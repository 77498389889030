import AdminLookupApi from "@/generatedapiclients/src/api/AdminLookupApi.js";

export default class AdminLookupService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminLookupApi = new AdminLookupApi();
  }
  async getAllSpotTypes() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSpotTypesGet(
      this._tenantId
    );
    return response;
  }
  async getAllPaymentTypes() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPaymentTypesGet(
      this._tenantId
    );
    return response.data;
  }
  async getAllAreas() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupAreasGet(
      this._tenantId
    );
    return response.data;
  }
  async getAreasForLocation(locationId) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupAreasLocationIdGet(
      locationId,
      this._tenantId
    );
    return response.lookupValues;
  }
  async getAllLoop() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupLoopsGet(
      this._tenantId
    );
    return response.data;
  }
  async getLoopsForLocation(locationId) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupLoopsLocationIdGet(
      locationId,
      this._tenantId
    );
    return response.lookupValues;
  }
  async getProductClassificationsForLocation(locationId) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGet(
      locationId,
      this._tenantId
    );
    return response.lookupValues;
  }
  async getSpotTypesForLocation(locationIds) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSpotTypesLocationsGet(
      this._tenantId,
      {
        locationIds
      }
    );
    return response.lookupValues;
  }
  async getAllSalesChannels() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSalesChannelsGet(
      this._tenantId
    );
    return response.data;
  }
  async getAllBlockReleases() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupBlockReleasesGet(
      this._tenantId
    );
    return response.data;
  }
  async getAllUnits() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupUnitsGet(
      this._tenantId
    );
    return response.data;
  }
  async getAllSeasonTypes() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSeasonTypesGet(
      this._tenantId
    );
    return response.data;
  }
  async getResidencyOptions() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupResidencyGet(
      this._tenantId
    );
    return response.data;
  }
  async updateSpotType(spotType) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSpotTypePut(
      this._tenantId,
      {
        spotType
      }
    );
    return response;
  }
  async getPointsOfInterest(pointsOfInterestRequest) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPointOfInterestsGet(
      this._tenantId,
      {
        pointsOfInterestRequest
      }
    );
    return response;
  }
  async getPointOfInterestCategories() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPointOfInterestCategoriesGet(
      this._tenantId
    );
    return response;
  }
  async updatePointOfInterest(pointOfInterest) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPointOfInterestsPut(
      this._tenantId,
      {
        pointOfInterest
      }
    );
    return response;
  }
  async getEquipmentTypes() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupEquipmenttypesGet(
      this._tenantId
    );
    return response;
  }
  async getLocations() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupLocationsGet(
      this._tenantId
    );
    return response.lookupValues;
  }
  async getRegions() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupRegionsGet(
      this._tenantId
    );
    return response.lookupValues;
  }
  async getSpotsForLocations(locationIds) {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupSpotsGet(
      this._tenantId,
      {
        locationIds
      }
    );
    return response.lookupValues;
  }
  async getPermissionAreas() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPermissionareasGet(
      this._tenantId
    );
    return response.data;
  }
  async getPermissions() {
    const response = await this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPermissionsGet(
      this._tenantId
    );
    return response.data;
  }
  async getPaymentTypes() {
    return this._adminLookupApi.v1TenantTenantIdAdminAdminLookupPaymenttypesGet(
      this._tenantId
    );
  }
  async getBookingTypes() {
    return this._adminLookupApi.v1TenantTenantIdAdminAdminLookupBookingTypesGet(
      this._tenantId
    );
  }
}
