/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AreaIEnumerableResponse from '../model/AreaIEnumerableResponse';
import BlockReleaseIEnumerableResponse from '../model/BlockReleaseIEnumerableResponse';
import BookingTypeIEnumerableResponse from '../model/BookingTypeIEnumerableResponse';
import EquipmentTypeIEnumerableResponse from '../model/EquipmentTypeIEnumerableResponse';
import LocationMinimalModelLookupResponse from '../model/LocationMinimalModelLookupResponse';
import LookupMinimalModelLookupResponse from '../model/LookupMinimalModelLookupResponse';
import LookupRequest from '../model/LookupRequest';
import LoopIEnumerableResponse from '../model/LoopIEnumerableResponse';
import PenaltyUnitIEnumerableResponse from '../model/PenaltyUnitIEnumerableResponse';
import PermissionAreaIEnumerableResponse from '../model/PermissionAreaIEnumerableResponse';
import PermissionIEnumerableResponse from '../model/PermissionIEnumerableResponse';
import PointOfInterest from '../model/PointOfInterest';
import PointOfInterestCategoryIEnumerableResponse from '../model/PointOfInterestCategoryIEnumerableResponse';
import PointOfInterestIEnumerableResponse from '../model/PointOfInterestIEnumerableResponse';
import PointOfInterestResponse from '../model/PointOfInterestResponse';
import ResidencyIEnumerableResponse from '../model/ResidencyIEnumerableResponse';
import SalesChannelIEnumerableResponse from '../model/SalesChannelIEnumerableResponse';
import SeasonTypeIEnumerableResponse from '../model/SeasonTypeIEnumerableResponse';
import SpotMinimalModelLookupResponse from '../model/SpotMinimalModelLookupResponse';
import SpotType from '../model/SpotType';
import SpotTypeResponse from '../model/SpotTypeResponse';
import SpotTypesResponse from '../model/SpotTypesResponse';
import TenantPaymentTypeIEnumerableResponse from '../model/TenantPaymentTypeIEnumerableResponse';
import UnitIEnumerableResponse from '../model/UnitIEnumerableResponse';

/**
* AdminLookup service.
* @module api/AdminLookupApi
* @version v1
*/
export default class AdminLookupApi {

    /**
    * Constructs a new AdminLookupApi. 
    * @alias module:api/AdminLookupApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AreaIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupAreasGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupAreasGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AreaIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/areas', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AreaIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupAreasGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupAreasGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupAreasLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLookupAreasLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupAreasLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/areas/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupAreasLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupAreasLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BlockReleaseIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupBlockReleasesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupBlockReleasesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BlockReleaseIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/blockReleases', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BlockReleaseIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupBlockReleasesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupBlockReleasesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BookingTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupBookingTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupBookingTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BookingTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/bookingTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BookingTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupBookingTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupBookingTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EquipmentTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupEquipmenttypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupEquipmenttypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EquipmentTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/equipmenttypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EquipmentTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupEquipmenttypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupEquipmenttypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupLocationsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupLocationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/locations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupLocationsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupLocationsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoopIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupLoopsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupLoopsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LoopIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/loops', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoopIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupLoopsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupLoopsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupLoopsLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLookupLoopsLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupLoopsLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/loops/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupLoopsLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupLoopsLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TenantPaymentTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPaymenttypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPaymenttypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TenantPaymentTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/paymenttypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TenantPaymentTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPaymenttypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupPaymenttypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PenaltyUnitIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPenaltyUnitsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPenaltyUnitsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PenaltyUnitIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/penaltyUnits', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PenaltyUnitIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPenaltyUnitsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupPenaltyUnitsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PermissionAreaIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPermissionareasGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPermissionareasGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PermissionAreaIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/permissionareas', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PermissionAreaIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPermissionareasGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupPermissionareasGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PermissionIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPermissionsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPermissionsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PermissionIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/permissions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PermissionIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPermissionsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupPermissionsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfInterestCategoryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestCategoriesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPointOfInterestCategoriesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfInterestCategoryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/pointOfInterestCategories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfInterestCategoryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestCategoriesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupPointOfInterestCategoriesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @param {Boolean} opts.isMappable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfInterestIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPointOfInterestsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'IsSearchable': opts['isSearchable'],
        'IsMappable': opts['isMappable']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfInterestIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/pointOfInterests', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @param {Boolean} opts.isMappable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfInterestIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupPointOfInterestsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfInterest} opts.pointOfInterest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfInterestResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestsPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['pointOfInterest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupPointOfInterestsPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfInterestResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/pointOfInterests', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PointOfInterest} opts.pointOfInterest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfInterestResponse}
     */
    v1TenantTenantIdAdminAdminLookupPointOfInterestsPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupPointOfInterestsPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/productClassifications/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupProductClassificationsLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupRegionsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupRegionsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/regions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupRegionsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupRegionsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ResidencyIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupResidencyGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupResidencyGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ResidencyIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/residency', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ResidencyIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupResidencyGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupResidencyGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SalesChannelIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSalesChannelsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSalesChannelsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SalesChannelIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/salesChannels', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SalesChannelIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupSalesChannelsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupSalesChannelsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SeasonTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSeasonTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSeasonTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SeasonTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/seasonTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SeasonTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupSeasonTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupSeasonTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotTypeDeleteWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotTypeDelete");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'id': opts['id']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spotType', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypeResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotTypeDelete(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupSpotTypeDeleteWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LookupRequest} opts.lookupRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotTypePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['lookupRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotTypePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spotType', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/LookupRequest} opts.lookupRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypeResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotTypePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupSpotTypePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotType} opts.spotType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotTypePutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['spotType'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotTypePut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spotType', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotType} opts.spotType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypeResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotTypePut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupSpotTypePutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypesResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spotTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypesResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupSpotTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.locationIds 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotTypesLocationsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotTypesLocationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'locationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spotTypes/locations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.locationIds 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotTypesLocationsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupSpotTypesLocationsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.locationIds 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupSpotsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupSpotsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'locationIds': this.apiClient.buildCollectionParam(opts['locationIds'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/spots', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.locationIds 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLookupSpotsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLookupSpotsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UnitIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLookupUnitsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLookupUnitsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UnitIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLookup/units', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UnitIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLookupUnitsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLookupUnitsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
